import React from 'react';
import { graphql } from 'gatsby';

import { container, wrapper, innerWrapper, aside, mainColumn } from './contact.module.scss';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IContactSection } from '../models/contact.model';
import { getNodes } from '../utils/get-nodes';
import useTranslations from '../hooks/use-translations';

import Heading from '../components/atoms/heading';
import MainLayout from '../layouts/main-layout';
import ContactSectionList from '../components/organisms/contact-section-list';

interface IContactPageProps {
    readonly data: {
        allContactSection: IQueryAllResult<IContactSection>;
    };
}

const Contact: React.FC<IContactPageProps> = ({ data }) => {
    const t = useTranslations('Contact');
    const sections = getNodes(data.allContactSection);

    return (
        <MainLayout className={container} hasOrnament={2}>
            <div className={wrapper}>
                <div className={innerWrapper}>
                    <div className={aside}>
                        <Heading numberOfStyle={2}>{t.header}</Heading>
                    </div>
                    <div className={mainColumn}>
                        <ContactSectionList sections={sections} />
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

export const query = graphql`
    query {
        allContactSection(sort: { fields: sequence, order: ASC }) {
            edges {
                node {
                    ...contactSectionFragment
                }
            }
        }
    }
`;

export default Contact;
