import React from 'react';

import {
    dataWrapper,
    image,
    imageRatio,
    contactInfo,
    name,
    value as valueClass,
    icon,
} from './contact-card.module.scss';

import relations from '../../config/relations';
import { IContactData } from '../../models/contact.model';

import RatioImage from '../atoms/ratio-image';
import Content from '../atoms/content';

import MailIcon from '../../assets/images/svg/mail.svg';
import PhoneIcon from '../../assets/images/svg/tel.svg';
import WebsiteIcon from '../../assets/images/svg/website.svg';
import AddressIcon from '../../assets/images/svg/address.svg';

interface IProfileCardProps {
    contact: Pick<
        IContactData,
        'label' | 'media' | 'emails' | 'phoneNumbers' | 'websites' | 'locations'
    >;
}

const ContactCard: React.FC<IProfileCardProps> = ({ contact }) => {
    const { media, label, emails, phoneNumbers, websites, locations } = contact;

    return (
        <div className={dataWrapper}>
            <RatioImage
                className={image}
                ratioClass={imageRatio}
                media={media}
                relation={relations.mainImage}
            />
            <div className={contactInfo}>
                <Content numberOfStyle={1} className={name}>
                    {label}
                </Content>
                {emails.map((email) => (
                    <a href={`mailto:${email}`} className={valueClass}>
                        <MailIcon className={icon} />
                        {email}
                    </a>
                ))}
                {phoneNumbers.map((phoneNumber) => (
                    <a href={`tel:${phoneNumber.value}`} className={valueClass}>
                        <PhoneIcon className={icon} />
                        {phoneNumber.label}
                    </a>
                ))}
                {websites.map((website) => (
                    <a
                        href={website.value}
                        target={'_blank'}
                        rel="noopener noreferrer"
                        className={valueClass}
                    >
                        <WebsiteIcon className={icon} />
                        {website.label}
                    </a>
                ))}
                {locations.map((location) => (
                    <span className={valueClass}>
                        <AddressIcon className={icon} />
                        {location}
                    </span>
                ))}
            </div>
        </div>
    );
};

export default ContactCard;
