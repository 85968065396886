import React from 'react';

import {
    categoryWrapper,
    headline,
    description as descriptionClass,
} from './contact-section-list.module.scss';

import { IContactSection } from '../../models/contact.model';

import ContactCard from '../molecules/contact-card';
import Heading from '../atoms/heading';
import Markdown from '../hoc/markdown';

interface IProfileListProps {
    sections: IContactSection[];
}

const ContactSectionList: React.FC<IProfileListProps> = ({ sections }) => {
    return (
        <div>
            {sections.map(({ sectionId, contacts, description, title }) => {
                return (
                    <div className={categoryWrapper} key={sectionId}>
                        <Heading className={headline} numberOfStyle={4}>
                            {title}
                        </Heading>
                        {contacts.map((contact) => {
                            return <ContactCard contact={contact} key={contact.groupId} />;
                        })}
                        <Markdown className={descriptionClass}>{description}</Markdown>
                    </div>
                );
            })}
        </div>
    );
};

export default ContactSectionList;
